/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "EncompassGateway",
            "endpoint": "https://2hp209opwj.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://qunfcffagncwbjqvilkvuybjgu.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-3iqjcxqqznhtlig4f5y7lw2n2i",
    "aws_cognito_identity_pool_id": "us-west-2:53c915b6-4565-4f08-b9fa-7b98130a1c2e",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_XuBDUfNEP",
    "aws_user_pools_web_client_id": "7s9u0v85o5uhoe9821i8615a5a",
    "oauth": {
        "domain": "devportalltkai.auth.us-west-2.amazoncognito.com"
    },
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 10,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "portal-storage145317-dev",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
